<template>
  <BaseModalSmall
    @close="close"
    @confirm="close"
    v-bind:isValidated="true"
    :noSlide="true"
  >
    <template v-slot:header>All Done!</template>
    <template v-slot:body>
      <section class="intro-body">
        <div>
          <p class="intro-box">
            Please allow 72 hours for us to check this document, we will email
            you once the checking process is completed (only then should the
            final document be signed).
          </p>
          <h5 class="intro-title">Any Questions?</h5>
          <p>
            If you have any questions in the meanwhile please don't hesitate to
            contact us either by phone or using Live Chat.
          </p>
        </div>
      </section>
    </template>
    <template v-slot:button>View Draft</template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'AllDoneModal',
  components: {
    BaseModalSmall
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.intro-box {
  padding: 15px;
  background-color: #faf9fe;
  color: #2f2c2c;
}
.intro-header {
  text-align: center;
  margin-bottom: 20px;
}
.intro-title {
  font-weight: 600;
  margin-top: 35px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.intro-body {
  font-size: 16px;
}
</style>
