<template>
  <NavQuestion v-bind:background="'bg-basket'" v-bind:questionOptions="true">
    <template v-slot:contents>
      <div v-show="showWill">
        <div class="col-lg-6 col-md-8 pb60">
          <div class="will-view-holder checking" id="pdf" ref="pdf"></div>
        </div>
        <div class="col-lg-4 col-lg-offset-2 col-md-4">
          <div class="viewwill-btn-wrapper">
            <div
              class="btn btn-medium btn--dark will-nav"
              v-on:click="nextPage"
              v-bind:disabled="false"
            >
              <span class="text">Next Page</span>
            </div>
          </div>
          <div class="viewwill-btn-wrapper">
            <div
              class="btn btn-medium btn--dark will-nav"
              v-on:click="previousPage"
              v-bind:disabled="false"
            >
              <span class="text">Previous Page</span>
            </div>
          </div>

          <div class="viewwill-btn-wrapper">
            <p class="disclaimer">
              Please allow 72 hours for us to check this document, we will email
              you once the checking process is completed (only then should the
              final document be signed). If you have any questions in the
              meanwhile please don't hesitate to contact us.
            </p>
          </div>
        </div>
      </div>
      <AllDoneModal
        v-show="showAllDone"
        @close="showAllDone = false"
      ></AllDoneModal>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
// import { http } from '@/services'
import AllDoneModal from './AllDoneModal'
import range from 'lodash/range'
// eslint-disable-next-line import/no-webpack-loader-syntax
// const Worker = require('worker-loader?inline=no-fallback!pdfjs-dist/build/pdf.worker')

export default {
  name: 'DraftWillPage',
  components: {
    NavQuestion,
    AllDoneModal
  },
  watch: {
    draftWill: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.willURL = value.pdf
          this.render()
        }
      }
    }
  },
  mounted() {
    this.$store.commit('checkoutProgress', {
      share: true
    })
    this.$store.dispatch('documentsFetch')
  },
  computed: {
    draftWill() {
      return this.$store.getters.documents.find(
        (doc) => doc.paid && !doc.checked
      )
      // return this.sortedWills
    },
    // sortedWills () {
    //   let wills = this.$store.getter.documents
    //   wills = wills.sort((a, b) => new Date(b.uploaded_at) - new Date(a.uploaded_at))
    //   if (wills.length) return wills[0]
    //   return null
    // }
  },
  data() {
    return {
      willURL: '',
      currentPage: [],
      currentPageShown: 1,
      showWill: true,
      pageCount: 1,
      src: null,
      showAllDone: false,
      pages: []
    }
  },
  methods: {
    nextPage() {
      var x = Array.from(document.querySelectorAll('.will-pdf'))

      var index = x.findIndex(function (element) {
        return element.className === 'pdf-page-canvas will-pdf view'
      })
      if (typeof x[index + 1] !== 'undefined') {
        x[index + 1].classList.add('view')
        x[index].classList.remove('view')
      }
    },
    previousPage() {
      var x = Array.from(document.querySelectorAll('.will-pdf'))

      var index = x.findIndex(function (element) {
        return element.className === 'pdf-page-canvas will-pdf view'
      })
      if (typeof x[index - 1] !== 'undefined') {
        x[index - 1].classList.add('view')
        x[index].classList.remove('view')
      }
    },
    render() {
      import('pdfjs-dist/build/pdf.js').then((pdfjs) => {
        pdfjs.GlobalWorkerOptions.workerSrc = 'pdfjs-dist/build/pdf.worker.js';

        var loadingTask = pdfjs.getDocument(this.willURL)
        loadingTask.promise
          .then((pdf) => {
            const pagePromises = range(1, pdf.numPages + 1).map((number) =>
              pdf.getPage(number)
            )
            return Promise.all(pagePromises)
          })
          .then(
            (pages) => {
              var count = 0
              pages.forEach((page) => {
                var scale = 2
                const viewport = page.getViewport({
                  scale: scale
                })

                // Prepare canvas using PDF page dimensions
                const canvas = document.createElement('canvas')
                canvas.height = viewport.height
                canvas.width = viewport.width

                // Render PDF page into canvas context
                const canvasContext = canvas.getContext('2d')
                const renderContext = {
                  canvasContext,
                  viewport
                }
                page.render(renderContext)
                const div = document.createElement('div')
                if (count === 0) div.className = 'pdf-page-canvas will-pdf view'
                else div.className = 'pdf-page-canvas will-pdf'
                this.$refs['pdf'].appendChild(div).appendChild(canvas)
                count += 1
              })
              this.showAllDone = true
            },
            (error) => console.log('Error', error)
          )
      })
    }
  }
}
</script>

<style scoped>
.pt60 {
  padding-top: 60px;
}
</style>
